import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import DetailLayout from '../containers/layout/detail-layout'

const ToegankelijksheidsverklaringPage = ({ location, pageContext }) => {
  const data = useStaticQuery(graphql`
    query ToegankelijksheidsverklaringPageQuery {
      site {
        siteMetadata {
          defaultTitle: title
          titleTemplate
          defaultDescription: description
          siteUrl
          defaultImage: image
        }
      }

      page: allContentfulPagina(
        filter: { id: { eq: "2d069218-0632-53ff-8a56-9056dbb46890" } }
      ) {
        edges {
          node {
            headerTitel
            headerSubtitel
            headerButtonTekst
            headerButtonLink
            headerImage {
              file {
                url
                fileName
                contentType
              }
              fluid(maxWidth: 900) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            description {
              description
            }
            content {
              json
            }
          }
        }
      }
    }
  `)

  const PageData = data.page.edges[0].node
  PageData.image = PageData.headerImage
  PageData.title = PageData.headerTitel
  PageData.subTitle = PageData.headerSubtitel
  pageContext.categories = []
  pageContext.metadata = data.site.siteMetadata

  return (
    <DetailLayout
      location={location}
      pageContext={pageContext}
      pageData={PageData}
    />
  )
}

export default ToegankelijksheidsverklaringPage
